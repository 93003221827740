import { Inject, Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {
  catchError,
  EMPTY,
  finalize,
  forkJoin,
  map,
  mergeMap,
  Observable,
  of,
} from 'rxjs';
import {
  API_ENDPOINT_RESOLVER,
  IApiEndpointResolver,
} from '@main-data-access-resolvers';
import {
  ISmartNavigatorService,
  NavigateToScreenRequest,
  SMART_NAVIGATOR_SERVICE,
} from '@ui-tool/core';
import {
  AUTHENTICATION_SERVICE,
  IAuthenticationService,
} from '@main-data-access-services';
import { Store } from '@ngrx/store';
import { IRootState, PortalLayoutActions } from '@main-data-access-stores';
import { ScreenCodes } from '@main-data-access-enums';
import { DS_ALERT_SERVICE_TOKEN, IDSAlertService } from '@design-system';

@Injectable()
export class AccessTokenInterceptor implements HttpInterceptor {
  //#region Constructor

  public constructor(
    @Inject(API_ENDPOINT_RESOLVER)
    protected readonly _apiEndpointResolver: IApiEndpointResolver,
    @Inject(SMART_NAVIGATOR_SERVICE)
    protected readonly _navigationService: ISmartNavigatorService,
    @Inject(AUTHENTICATION_SERVICE)
    protected readonly _authenticationService: IAuthenticationService,
    @Inject(DS_ALERT_SERVICE_TOKEN)
    protected readonly _alertService: IDSAlertService,
    protected readonly _store: Store<IRootState>
  ) {}

  //#endregion

  //#region Methods

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    this._store.dispatch(PortalLayoutActions.showLoading());
    return this._authenticationService.getAccessTokenAsync().pipe(
      catchError((exception) => of('')),
      mergeMap((accessToken) => {
        let httpHeaders = request.headers;

        if (accessToken) {
          httpHeaders = httpHeaders.set(
            'Authorization',
            `Bearer ${accessToken}`
          );
        }

        const clonedRequest = request.clone({
          headers: httpHeaders,
        });
        return next.handle(clonedRequest).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error?.status === 401) {
              this._alertService.error('Error', error.message);
              return forkJoin([
                this._authenticationService.deleteAccessTokenAsync(),
                this._navigationService.navigateToScreenAsync(
                  new NavigateToScreenRequest(ScreenCodes.LOGIN)
                ),
              ]).pipe(
                map(() => {
                  throw error;
                })
              );
            }
            return EMPTY;
          })
        );
      }),
      finalize(() => {
        this._store.dispatch(PortalLayoutActions.hideLoading());
      })
    );
  }

  //#endregion
}
